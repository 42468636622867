import React, { useCallback } from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { App, Button, Checkbox } from 'antd';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { typingDone, booleanToInt } from '../../../../utils/Utils';
import { Brand } from '../../../../../types/brand';
import InfoTooltip from '../../../global/InfoTooltip';
import FormInput from '../../../global/Forms/FormInput';
import FormCheckbox from '../../../global/Forms/FormCheckbox';
import FormSwitch from '../../../global/Forms/FormSwitch';
import AntTooltip from '../../../global/AntTooltip';

type BrandManagingTableProps = {
  keywords: string;
  canManageBrandSettings: boolean;
  canUseExportDB: boolean;
  handleBrandReset: (brandId: number) => void;
};

const BrandManagingTable: React.FC<BrandManagingTableProps> = ({
  keywords,
  canManageBrandSettings,
  canUseExportDB,
  handleBrandReset,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setValues } = useFormikContext<any>();
  const brands = values.brands;
  const { modal } = App.useApp();

  const sortList = useCallback(() => {
    const lowerKeywords = keywords.toLowerCase();
    return brands.filter((brand: Brand) => brand.name.toLowerCase().includes(lowerKeywords));
  }, [brands, keywords]);

  const [filteredBrands, setFilteredBrands] = React.useState(sortList());

  React.useEffect(() => {
    typingDone(() => {
      const sortedList = sortList();
      setFilteredBrands(sortedList);
    });
  }, [brands, keywords, sortList]);

  const getBrandRowIndex = (id: number, index: number) =>
    keywords ? brands.findIndex((b: Brand) => b.id === id) : index;

  const activeBrandCount = values.brands.filter((brand: Brand) => brand.active).length;
  const visibleToResellerCount = values.brands.filter(
    (brand: Brand) => brand.view_product_option_id === 3
  ).length;
  const autoPublishCount = values.brands.filter((brand: Brand) => brand.auto_sync === 1).length;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }}>
          <Table
            height={height}
            width={width - 2}
            className="brand-managing__table"
            rowClassName="brand-managing__table-row"
            headerHeight={40}
            rowCount={filteredBrands.length}
            dataSource={filteredBrands}
            rowHeight={40}
            rowGetter={({ index }) => filteredBrands[index]}
          >
            <Column
              label={t('brandManaging:active')}
              dataKey="active"
              width={45}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <FormCheckbox
                    name={`brands[${index}].active`}
                    handleChange={e => {
                      const index = getBrandRowIndex(rowData.id, rowIndex);
                      setFieldValue(`brands[${index}].active`, booleanToInt(e.target.checked));
                      if (!e.target.value) {
                        setFieldValue(`brands[${index}].view_product_option_id`, 4);
                        setFieldValue(`brands[${index}].oe_brand`, 0);
                        setFieldValue(`brands[${index}].auto_sync`, 0);
                      }
                    }}
                    disabled={!canManageBrandSettings}
                    fastField
                  />
                );
              }}
            />
            <Column
              label={t('brandManaging:name')}
              dataKey="name"
              width={300}
              flexGrow={1}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <AntTooltip
                    title={t('brandManaging:autoCareEditing')}
                    hide={!rowData.original_brand}
                  >
                    <div>
                      <FormInput
                        name={`brands[${index}].name`}
                        className={classNames('brand-managing__name-input', {
                          'border-invisible': !!rowData.name,
                        })}
                        size="small"
                        disabled={!!rowData.original_brand || !rowData.active}
                        fastField={!!rowData.original_brand || !rowData.active}
                      />
                    </div>
                  </AntTooltip>
                );
              }}
              headerRenderer={({ label }) => (
                <div className="flex pl-2">
                  <span>{label}</span>
                </div>
              )}
            />
            <Column
              label={t('brandManaging:internalCode')}
              dataKey="code"
              width={120}
              cellRenderer={({ rowData }) => <span className="cursor-default">{rowData.code}</span>}
            />
            <Column
              label={t('brandManaging:brandCode')}
              dataKey="default_whitelabel_brand_code"
              width={135}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <AntTooltip
                    title={t('brandManaging:autoCareEditing')}
                    hide={!rowData.original_brand}
                  >
                    <div>
                      <FormInput
                        name={`brands[${index}].default_whitelabel_brand_code`}
                        className={classNames({ 'border-invisible': !!rowData.name })}
                        placeholder={
                          rowData.original_brand
                            ? `${rowData.code} (${t('brandManaging:autoCare')})`
                            : rowData.code
                        }
                        size="small"
                        disabled={!!rowData.original_brand || !rowData.active}
                        fastField={!rowData.new}
                      />
                    </div>
                  </AntTooltip>
                );
              }}
              headerRenderer={({ label }) => (
                <div className="flex pl-2">
                  <span>{label}</span>
                </div>
              )}
            />
            <Column
              label={t('brandManaging:visible')}
              dataKey="view_product_option_id"
              width={160}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <FormCheckbox
                    name={`brands[${index}].view_product_option_id`}
                    checkedValue={3}
                    handleChange={e => {
                      const index = getBrandRowIndex(rowData.id, rowIndex);
                      // 3: visible, 4: not visible
                      setFieldValue(
                        `brands[${index}].view_product_option_id`,
                        e.target.checked ? 3 : 4
                      );
                    }}
                    disabled={!rowData.active}
                    fastField={!!rowData.active}
                  />
                );
              }}
              headerRenderer={({ label }) => (
                <div className="flex pl-px">
                  <Checkbox
                    indeterminate={
                      activeBrandCount > 0 &&
                      visibleToResellerCount > 0 &&
                      visibleToResellerCount !== activeBrandCount
                    }
                    checked={visibleToResellerCount === activeBrandCount}
                    onChange={e => {
                      const checked = e.target.checked;
                      // 3: visible, 4: not visible
                      setValues({
                        brands: values.brands.map((brand: Brand) => ({
                          ...brand,
                          view_product_option_id: checked && brand.active ? 3 : 4,
                        })),
                      });
                    }}
                  >
                    {label}
                  </Checkbox>
                </div>
              )}
            />
            <Column
              label={t('brandManaging:autoPublish')}
              dataKey="auto_sync"
              width={150}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <FormCheckbox
                    name={`brands[${index}].auto_sync`}
                    handleChange={e => {
                      const index = getBrandRowIndex(rowData.id, rowIndex);
                      setFieldValue(`brands[${index}].auto_sync`, booleanToInt(e.target.checked));
                    }}
                    disabled={!rowData.active || !canUseExportDB}
                    fastField={!!rowData.active}
                  />
                );
              }}
              headerRenderer={({ label }) => (
                <div className="flex pl-px">
                  <Checkbox
                    indeterminate={
                      activeBrandCount > 0 &&
                      autoPublishCount > 0 &&
                      autoPublishCount !== activeBrandCount
                    }
                    checked={autoPublishCount === activeBrandCount}
                    onChange={e => {
                      const checked = e.target.checked;
                      setValues({
                        brands: values.brands.map((brand: Brand) => ({
                          ...brand,
                          auto_sync: checked && brand.active ? 1 : 0,
                        })),
                      });
                    }}
                    disabled={!canUseExportDB}
                  >
                    {label}
                  </Checkbox>
                  <InfoTooltip
                    className="self-center"
                    overlay={t('brandManaging:autoPublishDesc')}
                  />
                </div>
              )}
            />
            <Column
              label={t('brandManaging:oem')}
              dataKey="oe_brand"
              width={100}
              flexGrow={1}
              cellRenderer={({ rowData, rowIndex }) => {
                const index = getBrandRowIndex(rowData.id, rowIndex);
                return (
                  <div className="flex items-center">
                    <FormSwitch
                      name={`brands[${index}].oe_brand`}
                      handleChange={checked => {
                        const prevOem = brands.find((b: Brand) => b.oe_brand === 1);
                        if (!checked || !prevOem) {
                          const index = getBrandRowIndex(rowData.id, rowIndex);
                          setFieldValue(`brands[${index}].oe_brand`, booleanToInt(checked));
                        } else {
                          modal.confirm({
                            title: t('brandManaging:changeOemTitle', { brand: rowData.name }),
                            icon: <ExclamationCircleOutlined />,
                            content: t('brandManaging:changeOemDesc', {
                              brand: rowData.name,
                              oldBrand: prevOem.name,
                            }),
                            okText: t('common:assign'),
                            onOk() {
                              values.brands.forEach((b: Brand, i: number) => {
                                setFieldValue(
                                  `brands[${i}].oe_brand`,
                                  booleanToInt(b.id === rowData.id)
                                );
                              });
                            },
                          });
                        }
                      }}
                      disabled={!rowData.active}
                      fastField={!!rowData.active}
                      size="small"
                    />
                    {!!rowData.oe_brand && (
                      <span className="ml-2 text-xs text-gray-600">{`(${t(
                        'brandManaging:oemSelected'
                      )})`}</span>
                    )}
                  </div>
                );
              }}
              headerRenderer={({ label }) => (
                <div className="flex">
                  <span>{label}</span>
                  <InfoTooltip
                    className="ml-2 self-center"
                    overlay={t('brandManaging:singleOemHint')}
                  />
                </div>
              )}
            />
            <Column
              dataKey="date"
              width={60}
              cellRenderer={({ rowData }) => (
                <Button onClick={() => handleBrandReset(rowData.id)} size="small" danger>
                  {t('common:reset')}
                </Button>
              )}
            />
          </Table>
        </div>
      )}
    </AutoSizer>
  );
};

export default BrandManagingTable;
