import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, Button } from 'antd';
import { CloudOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../global/InfoTooltip';
import OutOfSyncTableRow from './OutOfSyncTableRow';

interface SyncItemsStatusProps {
  autoSync: boolean;
  outOfSyncItems: { id: number; [key: string]: any }[];
  fetching: boolean;
  canPublishItems: boolean;
  handleSyncItems: (selectedItemIds: number[]) => void;
  handleBrandSync: () => void;
  handleSyncAtNight: (selectedItemIds: number[]) => void;
  handleAddToTempFilter: (selectedItemIds: number[]) => void;
  handleScroll: (e: React.UIEvent<HTMLDivElement>) => void;
}

const OutOfSyncTable: React.FC<SyncItemsStatusProps> = ({
  autoSync,
  outOfSyncItems,
  fetching,
  canPublishItems,
  handleSyncItems,
  handleBrandSync,
  handleSyncAtNight,
  handleAddToTempFilter,
  handleScroll,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modal } = App.useApp();

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  useEffect(() => {
    // reset selected items when outOfSyncItems changes
    setSelectedItemIds([]);
  }, [outOfSyncItems.length]);

  const handleSelectItem = (itemId: number) => {
    const isSelected = selectedItemIds.includes(itemId);

    if (isSelected) {
      const filteredSelectedItemIds = selectedItemIds.filter(
        selectedItemId => selectedItemId !== itemId
      );
      setSelectedItemIds(filteredSelectedItemIds);
    } else {
      setSelectedItemIds([...selectedItemIds, itemId]);
    }
  };

  const confirmSyncAllBrand = () => {
    const action = () => handleBrandSync();
    modal.confirm({
      title: t('publishData:publishWarning'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        action();
      },
    });
  };

  return (
    <div className="synchronisation-table__container">
      <div className="synchronisation-table">
        <div className="synchronisation-table__header">
          <div className="synchronisation-table__left">
            <div className="synchronisation-table__col header-first " />
            <div className="synchronisation-table__col col--fix">
              {t('synchronisation:part_number')}
            </div>
            <div className="synchronisation-table__col col--full">
              {t('synchronisation:staging_db')}
            </div>
          </div>
          <div className="synchronisation-table__right">
            <div className="synchronisation-table__col col--full">
              {t('synchronisation:receiver_db')}
            </div>
            <div className="synchronisation-table__col col--fix">
              {t('synchronisation:last_publish')}
            </div>
            <div className="synchronisation-table__col col--fix">
              {t('synchronisation:updated_at')}
            </div>
          </div>
        </div>
        <div className="synchronisation-table__body" onScroll={handleScroll}>
          {outOfSyncItems.length > 0 &&
            outOfSyncItems.map(item => (
              <OutOfSyncTableRow
                key={item.id}
                item={item}
                handleSelectItem={handleSelectItem}
                selectedItemIds={selectedItemIds}
              />
            ))}
          {outOfSyncItems.length === 0 && !fetching && (
            <div className="all-products-sync">
              <CloudOutlined />
              <span className="all-products-sync__desc">
                {t('synchronisation:all_items_published')}
              </span>
            </div>
          )}
          {fetching && <div className="loader" />}
        </div>
      </div>
      {outOfSyncItems.length > 0 && (
        <div>
          <div className="synchronisation-table__sum">
            <span>
              {t('synchronisation:selected')} {selectedItemIds.length} {t('synchronisation:items')}
            </span>
          </div>
          <div className="synchronisation-table__actions">
            <Button
              name={t('synchronisation:publish_items')}
              onClick={() => handleSyncItems(selectedItemIds)}
              disabled={!canPublishItems || selectedItemIds.length === 0}
              type="primary"
            >
              {t('synchronisation:publish_items')}
            </Button>
            <Button onClick={confirmSyncAllBrand} disabled={!canPublishItems} type="primary">
              {t('synchronisation:publish_all_brand_items')}
            </Button>
            <Button
              className="actions__sync-night"
              onClick={() => handleSyncAtNight(selectedItemIds)}
              disabled={!canPublishItems || selectedItemIds.length === 0}
            >
              {t('synchronisation:publish_items_at_night')}
            </Button>
            <Button
              className="actions__show-products"
              name={t('synchronisation:add_to_temp_filter')}
              onClick={() => handleAddToTempFilter(selectedItemIds)}
              disabled={!canPublishItems || selectedItemIds.length === 0}
            >
              {t('synchronisation:add_to_temp_filter')}
            </Button>
            {autoSync && (
              <div className="flex-1 flex items-end">
                <div className="w-52 ml-auto">
                  <span className="">{t('synchronisation:auto_publish1')}</span>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: '5px', paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => navigate(`/settings/brands`)}
                    type="link"
                    size="small"
                  >
                    {t('synchronisation:brand_settings_link')}
                  </Button>
                  <span>{t('synchronisation:auto_publish2')}</span>
                </div>
                <div className="h-full flex align-center">
                  <InfoTooltip overlay={t('brandManaging:autoPublishDesc')} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OutOfSyncTable;
