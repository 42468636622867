import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApplicationState } from '../../reducers';
import {
  fetchOutOfSyncItems,
  syncItems,
  syncAtNight,
  fetchOutOfSyncItemsCount,
} from '../../actions/brand/synchronisation';
import { getTempProductsFilter } from '../../selectors/catalogue/filterSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { nextPage } from '../../utils/Utils';
import OutOfSyncTable from '../../components/body/synchronisation/OutOfSyncTable';
import filterActions from '../../actions/catalogue/filter';
import { intercomEvent } from '../../utils/IntercomUtils';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import { convertToGoFilterStructure } from '../../utils/FilterUtils';

const SynchronisationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    fetchingOutOfSyncItems,
    brandId,
    brands,
    outOfSyncItems,
    tempProductsFilter,
    user,
    brandCode,
    goOnly,
  } = useSelector((state: ApplicationState) => {
    return {
      fetchingOutOfSyncItems: state.brand.synchronisation.fetchingOutOfSyncItems,
      brandId: state.parent.brands.selectedBrandId,
      brands: state.parent.brands.brands,
      outOfSyncItems: state.brand.synchronisation.outOfSyncItems,
      tempProductsFilter: getTempProductsFilter(state),
      user: state.user.user,
      brandCode: getSelectedBrandCode(state),
      goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
    };
  });

  const canPublishItems = useMemo(() => hasPermission(user, 'can_publish_products'), [user]);
  const currentBrand = useMemo(() => brands.find(brand => brand.id === brandId), [brands, brandId]);

  React.useEffect(() => {
    dispatch(fetchOutOfSyncItems(brandId));
    dispatch(fetchOutOfSyncItemsCount(brandId));
    intercomEvent('viewed-publish-data', { brand_code: brandCode! });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSyncItems = (itemIds: number[]) => {
    dispatch(syncItems({ itemIds, brandId }));
  };

  const handleBrandSync = () => {
    dispatch(syncItems({ brandId }));
  };

  const handleSyncAtNight = (itemIds: number[]) => {
    dispatch(syncAtNight(itemIds, brandId));
  };

  const handleAddToTempFilter = async (itemIds: number[]) => {
    await dispatch(filterActions.resetFilter(tempProductsFilter.id));

    if (goOnly) {
      // // updateTempFilterItems
      // await this.props.dispatch(
      //   filterActions.updateFilterGo({
      //     ...response.value.data.filter,
      //     item_ids: itemIds.join(),
      //   })
      // );
    } else {
      await dispatch(filterActions.updateTempFilterItems(tempProductsFilter.id, itemIds));
      // this.props.dispatch(
      //   filterActions.updateFilterGo(
      //     convertToGoFilterStructure({
      //       ...updateItemsResponse.value.data.filter,
      //       item_ids: itemIds.join(),
      //     })
      //   )
      // );
    }
    navigate(`/brand/products/item?brandId=${brandId}&filterId=${tempProductsFilter.id}`);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const newPage = nextPage(e, 100, outOfSyncItems.length);
    if (newPage && !fetchingOutOfSyncItems) {
      dispatch(fetchOutOfSyncItems(brandId, newPage));
    }
  };

  return (
    <div className="synchronisation-container">
      <div className="synchronisation-content">
        <OutOfSyncTable
          autoSync={!!currentBrand!.auto_sync}
          canPublishItems={canPublishItems}
          fetching={fetchingOutOfSyncItems}
          handleScroll={handleScroll}
          outOfSyncItems={outOfSyncItems}
          handleBrandSync={handleBrandSync}
          handleSyncItems={handleSyncItems}
          handleSyncAtNight={handleSyncAtNight}
          handleAddToTempFilter={handleAddToTempFilter}
        />
      </div>
    </div>
  );
};

export default SynchronisationContainer;
